import type {SignatureService, Configuration} from "../services/signature.service";
import {
    type ConfigureRequest,
    ConfigureResponse,
    ErrorResponse,
    Event,
    type ExportPrivateKeyRequest, ExportPrivateKeyResponse,
    type GetCurrentDeviceRequest,
    GetCurrentDeviceResponse,
    type IEvent, INCORRECT_USER_ENTROPY_ERROR,
    LogoutResponse, MISSING_PROJECT_ENTROPY_ERROR,
    MISSING_USER_ENTROPY_ERROR,
    NOT_CONFIGURED_ERROR,
    PongResponse,
    type SetRecoveryMethodRequest,
    SetRecoveryMethodResponse,
    type SignRequest,
    SignResponse,
    type UpdateAuthenticationRequest,
    UpdateAuthenticationResponse,
} from "./events";
import {
    IncorrectUserEntropyError,
    MissingProjectEntropyError,
    MissingUserEntropyError,
    NotConfiguredError,
} from "../services/errors";
import type {Context} from "../lib/context";

export class EmbeddedController {
    private readonly _signatureService: SignatureService;
    private readonly _responseWindow: Window;

    constructor(signatureService: SignatureService, window: Window) {
        this._signatureService = signatureService;
        this._responseWindow = window;
    }

    async Configure(ctx: Context, event: MessageEvent): Promise<void> {
        const request: ConfigureRequest = event.data;
        try {
            const configuration = this.toServiceConfiguration(request);
            const accountDevice = await this._signatureService.configure(ctx, configuration);
            const response = new ConfigureResponse(request.uuid, accountDevice.deviceID, accountDevice.accountType, accountDevice.chainId, accountDevice.address);

            this._responseWindow.postMessage(response, "*");
        } catch (e) {
            let errorMessage = `${e}`;
            if (e instanceof MissingUserEntropyError) {
                errorMessage = MISSING_USER_ENTROPY_ERROR;
            } else if (e instanceof MissingProjectEntropyError) {
                errorMessage = MISSING_PROJECT_ENTROPY_ERROR;
            } else if (e instanceof IncorrectUserEntropyError) {
                errorMessage = INCORRECT_USER_ENTROPY_ERROR;
            }
            const response = new ErrorResponse(request.uuid,Event.CONFIGURED, errorMessage);
            this._responseWindow.postMessage(response, "*");
        }
    }

    async Sign(ctx: Context, event: MessageEvent): Promise<void> {
        const request: SignRequest = event.data;
        try {
            const signature = await this._signatureService.sign(ctx, request.message, request.requireArrayify, request.requireHash, request.openfortConfiguration);
            const response = new SignResponse(request.uuid, signature);

            this._responseWindow.postMessage(response, "*");
        } catch (e) {
            let errorMessage = `${e}`;
            if (e instanceof NotConfiguredError) {
                errorMessage = NOT_CONFIGURED_ERROR;
            } else if (e instanceof MissingProjectEntropyError) {
                errorMessage = MISSING_PROJECT_ENTROPY_ERROR;
            } else if (e instanceof MissingUserEntropyError) {
                errorMessage = MISSING_USER_ENTROPY_ERROR;
            }
            const response = new ErrorResponse(request.uuid, Event.SIGNED, errorMessage);
            this._responseWindow.postMessage(response, "*");
        }
    }

    UpdateAuthentication(ctx: Context, event: MessageEvent): Promise<void> { // eslint-disable-line @typescript-eslint/require-await
        const request: UpdateAuthenticationRequest = event.data;
        try {
            this._signatureService.updateAuthentication(ctx, request.accessToken);
            this._responseWindow.postMessage(new UpdateAuthenticationResponse(request.uuid), "*");
            return Promise.resolve();
        } catch (e) {
            let errorMessage = `${e}`;
            if (e instanceof NotConfiguredError) {
                errorMessage = NOT_CONFIGURED_ERROR;
            } else if (e instanceof MissingProjectEntropyError) {
                errorMessage = MISSING_PROJECT_ENTROPY_ERROR;
            } else if (e instanceof MissingUserEntropyError) {
                errorMessage = MISSING_USER_ENTROPY_ERROR;
            }
            const response = new ErrorResponse(request.uuid, Event.AUTHENTICATION_UPDATED, errorMessage);
            this._responseWindow.postMessage(response, "*");
            return Promise.resolve();
        }
    }

    GetCurrentDevice(ctx: Context, event: MessageEvent): Promise<void> {
        const request: GetCurrentDeviceRequest = event.data;
        try {
            const accountDevice = this._signatureService.getCurrentDevice(ctx, request.playerID);
            this._responseWindow.postMessage(new GetCurrentDeviceResponse(request.uuid, accountDevice?.deviceID || null, accountDevice?.accountType || null, accountDevice?.chainId || null, accountDevice?.address || null), "*");
        } catch (e) {
            let errorMessage = `${e}`;
            if (e instanceof NotConfiguredError) {
                errorMessage = NOT_CONFIGURED_ERROR;
            } else if (e instanceof MissingProjectEntropyError) {
                errorMessage = MISSING_PROJECT_ENTROPY_ERROR;
            } else if (e instanceof MissingUserEntropyError) {
                errorMessage = MISSING_USER_ENTROPY_ERROR;
            }
            const response = new ErrorResponse(request.uuid, Event.CURRENT_DEVICE, errorMessage);
            this._responseWindow.postMessage(response, "*");
        }
        return Promise.resolve();
    }

    Ping(ctx: Context, event: MessageEvent): Promise<void> {
        const request: GetCurrentDeviceRequest = event.data;
        this._responseWindow.postMessage(new PongResponse(request.uuid), "*");
        return Promise.resolve();
    }

    Logout(ctx: Context, event: MessageEvent): Promise<void> {
        const request: IEvent = event.data;
        this._signatureService.logout(ctx);
        this._responseWindow.postMessage(new LogoutResponse(request.uuid), "*");
        return Promise.resolve();
    }

    async Export(ctx: Context, event: MessageEvent): Promise<void> {
        const request: ExportPrivateKeyRequest = event.data;
        try {
            const key = await this._signatureService.export(ctx, request.requestConfiguration);
            const response = new ExportPrivateKeyResponse(request.uuid, key);

            this._responseWindow.postMessage(response, "*");
        } catch (e) {
            let errorMessage = `${e}`;
            if (e instanceof NotConfiguredError) {
                errorMessage = NOT_CONFIGURED_ERROR;
            } else if (e instanceof MissingProjectEntropyError) {
                errorMessage = MISSING_PROJECT_ENTROPY_ERROR;
            } else if (e instanceof MissingUserEntropyError) {
                errorMessage = MISSING_USER_ENTROPY_ERROR;
            }
            const response = new ErrorResponse(request.uuid, Event.EXPORT, errorMessage);
            this._responseWindow.postMessage(response, "*");
        }
    }

    async SetRecoveryMethod(ctx: Context, event: MessageEvent): Promise<void> {
        const request: SetRecoveryMethodRequest = event.data;
        try {
            await this._signatureService.setRecoveryMethod(ctx, request.recoveryMethod, request.recoveryPassword, request.encryptionSession, request.requestConfiguration);
            const response = new SetRecoveryMethodResponse(request.uuid);

            this._responseWindow.postMessage(response, "*");
        } catch (e) {
            let errorMessage = `${e}`;
            if (e instanceof NotConfiguredError) {
                errorMessage = NOT_CONFIGURED_ERROR;
            } else if (e instanceof MissingProjectEntropyError) {
                errorMessage = MISSING_PROJECT_ENTROPY_ERROR;
            } else if (e instanceof MissingUserEntropyError) {
                errorMessage = MISSING_USER_ENTROPY_ERROR;
            }
            const response = new ErrorResponse(request.uuid, Event.SET_RECOVERY_METHOD, errorMessage);
            this._responseWindow.postMessage(response, "*");
        }
    }

    private toServiceConfiguration(request: ConfigureRequest) {
        const configuration: Configuration = {
            chainId: request.chainId,
            encryptionKey: request.encryptionKey,
            openfortURL: request.openfortURL,
            publishableKey: request.publishableKey,
            shieldAPIKey: request.shieldAPIKey,
            shieldURL: request.shieldURL,
            token: request.accessToken,
            playerID: request.playerID,
            thirdPartyProvider: request.thirdPartyProvider,
            thirdPartyTokenType: request.thirdPartyTokenType,
            encryptionPart: request.encryptionPart,
            encryptionSession: request.encryptionSession,
        };

        if (request.recovery) {
            configuration.ShieldAuthentication = {
                auth: request.recovery.auth,
                token: request.recovery.token,
                authProvider: request.recovery.authProvider,
                tokenType: request.recovery.tokenType,
            };
        }
        return configuration;
    }
}
