import type React from "react";
import {useEffect, useRef} from "react";
import {EmbeddedController} from "./controllers/embeddedController";
import {SignatureService} from "./services/signature.service";
import {EventManager} from "./controllers/eventManager";
import {Event} from "./controllers/events";
import {VERSION} from "./version";

const App: React.FC = () => {
    const debugMode = useRef<boolean>(true);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const debugParam = params.get("debug");
        if (debugParam) {
            debugMode.current = debugParam === "true";
        } else {
            debugMode.current = false;
        }
        if (window === window.parent) {
            console.error("cannot run in this context");
            return;
        }

        cacheBuster().then(() => {
            const signatureService = new SignatureService(debugMode.current);
            const messageController = new EmbeddedController(signatureService, window.parent);
            const server = new EventManager(debugMode.current);
            server.on(Event.CONFIGURE, messageController.Configure.bind(messageController));
            server.on(Event.SIGN, messageController.Sign.bind(messageController));
            server.on(Event.UPDATE_AUTHENTICATION, messageController.UpdateAuthentication.bind(messageController));
            server.on(Event.LOGOUT, messageController.Logout.bind(messageController));
            server.on(Event.EXPORT, messageController.Export.bind(messageController));
            server.on(Event.SET_RECOVERY_METHOD, messageController.SetRecoveryMethod.bind(messageController));
            server.on(Event.GET_CURRENT_DEVICE, messageController.GetCurrentDevice.bind(messageController));
            server.on(Event.PING, messageController.Ping.bind(messageController));
            server.listen();
        });
    }, []);

    return <div>OK</div>;
};
export default App;

const cacheBuster = async () => {
    try {
        const metadata = await getMetadata();
        if (metadata?.version) {
            const {version} = metadata;
            if (version !== VERSION) {
                await refreshCacheAndReload();
            }
        }
    } catch (error) {
        console.error("Error checking metadata:", error);
    }
};

const getMetadata = async () => {
    try {
        const response = await fetch("/meta.json");
        return await response.json();
    } catch (error) {
        console.error("Error fetching metadata:", error);
    }
};

const refreshCacheAndReload = async () => {
    try {
        if (window?.caches) {
            const {caches} = window;
            const cacheNames = await caches.keys();
            const cacheDeletionPromises = cacheNames.map((n) => caches.delete(n));

            await Promise.all(cacheDeletionPromises);

            window.location.reload();
        }
    } catch (error) {
        console.error("Error refreshing cache and reloading:", error);
    }
};
