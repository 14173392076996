export class NotConfiguredError extends Error {
    constructor(message = "Domain error") {
        super(message);
        this.name = "NotConfiguredError";
        Object.setPrototypeOf(this, NotConfiguredError.prototype);
    }
}

export class MissingUserEntropyError extends Error {
    constructor(message = "Domain error") {
        super(message);
        this.name = "MissingUserEntropyError";
        Object.setPrototypeOf(this, MissingUserEntropyError.prototype);
    }
}

export class MissingProjectEntropyError extends Error {
    constructor(message = "Domain error") {
        super(message);
        this.name = "MissingProjectEntropyError";
        Object.setPrototypeOf(this, MissingProjectEntropyError.prototype);
    }
}

export class IncorrectUserEntropyError extends Error {
    constructor(message = "Domain error") {
        super(message);
        this.name = "IncorrectUserEntropyError";
        Object.setPrototypeOf(this, IncorrectUserEntropyError.prototype);
    }
}
