
export function uuidv7() {
    // random bytes
    const value = new Uint8Array(16);
    for (let i = 0; i < value.length; i++) {
        value[i] = Math.floor(Math.random() * 256);
    }

    // current timestamp in ms
    const timestamp = BigInt(Date.now());

    // timestamp
    const mask8Bits = BigInt(0xff);

    value[0] = Number((timestamp >> BigInt(40)) & mask8Bits);
    value[1] = Number((timestamp >> BigInt(32)) & mask8Bits);
    value[2] = Number((timestamp >> BigInt(24)) & mask8Bits);
    value[3] = Number((timestamp >> BigInt(16)) & mask8Bits);
    value[4] = Number((timestamp >> BigInt(8)) & mask8Bits);
    value[5] = Number(timestamp & mask8Bits);

    // version and variant
    value[6] = (value[6] & 0x0f) | 0x70;
    value[8] = (value[8] & 0x3f) | 0x80;

    return Array.from(value)
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
}
