import {
    Device,
    Embedded,
    NextAction,
} from "./types";
import axios, {AxiosRequestConfig} from "axios";
import {importJWK, jwtVerify} from "jose";
import {JwtKeyResponse} from "../services/signature.service";
import {Context, ContextKey} from "../lib/context";

export class Openfort {
    private readonly _baseURL: string = "https://api.openfort.xyz";
    private readonly _publishableKey: string;
    private _accessToken: string | undefined = undefined;
    private readonly thirdPartyProvider: string | undefined = undefined;
    private readonly thirdPartyTokenType: string | undefined = undefined;

    constructor(publishableKey: string, accessToken: string | undefined = undefined, thirdPartyProvider: string | undefined = undefined, thirdPartyTokenType: string | undefined = undefined, baseURL = "https://api.openfort.xyz") {
        this._publishableKey = publishableKey;
        this._accessToken = accessToken;
        this.thirdPartyProvider = thirdPartyProvider;
        this.thirdPartyTokenType = thirdPartyTokenType;
        this._baseURL = baseURL;
    }

    public setAccessToken(token: string) {
        this._accessToken = token;
    }

    private getAuthHeaders(ctx: Context): AxiosRequestConfig {
        if (this.thirdPartyProvider && this.thirdPartyTokenType) {
            return {
                headers: {
                    Authorization: `Bearer ${this._publishableKey}`,
                    "X-Player-Token": this._accessToken,
                    "X-Auth-Provider": this.thirdPartyProvider,
                    "X-Token-Type": this.thirdPartyTokenType,
                    "x-request-id": ctx.value(ContextKey.REQUEST),
                },
            };
        }
        return {
            headers: {
                Authorization: `Bearer ${this._publishableKey}`,
                "X-Player-Token": this._accessToken,
                "x-request-id": ctx.value(ContextKey.REQUEST),
            },
        };
    }

    public async init(ctx: Context, chainId: number): Promise<NextAction> {
        try {
            const res = await axios.post(`${this._baseURL}/v1/devices/init`, {
                chainId: chainId,
            }, this.getAuthHeaders(ctx));

            const response: NextAction = res.data;
            return response;
        } catch (error: any) {
            if (error.response) {
                console.error(`unexpected response: ${error.response.status}: ${error.response.data}`);
            } else if (error.request) {
                console.error(`no response: ${error.request}`);
            } else {
                console.error(`unexpected error: ${error}`);
            }

            throw error;
        }
    }

    public async register(ctx: Context, chainId: number, address: string, share: string): Promise<Embedded> {
        try {
            const res = await axios.post(`${this._baseURL}/v1/devices/register`, {
                chainId: chainId,
                address: address,
                share: share,
            }, this.getAuthHeaders(ctx));

            const response: Embedded = res.data;
            return response;
        } catch (error: any) {
            if (error.response) {
                console.error(`unexpected response: ${error.response.status}: ${error.response.data}`);
            } else if (error.request) {
                console.error(`no response: ${error.request}`);
            } else {
                console.error(`unexpected error: ${error}`);
            }

            throw error;
        }
    }

    public async exported(ctx: Context, address: string): Promise<void> {
        try {
            const res = await axios.post(`${this._baseURL}/v1/devices/exported`, {
                address: address,
            }, this.getAuthHeaders(ctx));

            if (res.status !== 201) {
                console.error(`unexpected response: ${res.status}: ${res.data}`);
                throw new Error(`unexpected response: ${res.status}: ${res.data}`);
            }

        } catch (error: any) {
            if (error.response) {
                console.error(`unexpected response: ${error.response.status}: ${error.response.data}`);
            } else if (error.request) {
                console.error(`no response: ${error.request}`);
            } else {
                console.error(`unexpected error: ${error}`);
            }

            throw error;
        }
    }

    public async getDevice(ctx: Context, deviceID: string): Promise<Device> {
        try {
            const res = await axios.get(`${this._baseURL}/v1/devices/${deviceID}`, this.getAuthHeaders(ctx));

            const response: Device = res.data;
            return response;
        } catch (error: any) {
            if (error.response) {
                console.error(`unexpected response: ${error.response.status}: ${error.response.data}`);
            } else if (error.request) {
                console.error(`no response: ${error.request}`);
            } else {
                console.error(`unexpected error: ${error}`);
            }

            throw error;
        }
    }

    public async verifyToken(ctx: Context, accessToken: string): Promise<string | null> {
        try {
            const res = await axios.get(`${this._baseURL}/iam/v1/${this._publishableKey}/jwks.json`, {
                headers: {
                    Authorization: `Bearer ${this._publishableKey}`,
                    "x-request-id": ctx.value(ContextKey.REQUEST),
                },
            });

            const jwtks = res.data as JwtKeyResponse;
            if (!jwtks.keys || jwtks.keys.length === 0) {
                console.error("failed to get jwks");
                return null;
            }

            const jwtk = jwtks.keys[0];
            const ecPublicKey = await importJWK(
                {
                    kty: jwtk.kty,
                    crv: jwtk.crv,
                    x: jwtk.x,
                    y: jwtk.y,
                },
                jwtk.alg,
            );

            const verification = await jwtVerify(accessToken, ecPublicKey);
            if (!verification.payload || !verification.payload.sub) {
                console.error("failed to verify token");
                return null;
            }

            return verification.payload.sub;


        } catch (e) {
            console.error("failed to verify token", e);
            return null;
        }
    }

    async verifyThirdParty(ctx: Context, token: string, thirdPartyProvider: string, thirdPartyTokenType: string) {
        try {
            const res = await axios.post(`${this._baseURL}/iam/v1/oauth/third_party`, {
                token: token,
                provider: thirdPartyProvider,
                tokenType: thirdPartyTokenType,
            }, {
                headers: {
                    Authorization: `Bearer ${this._publishableKey}`,
                    "x-request-id": ctx.value(ContextKey.REQUEST),
                },
            });

            return res.data.id;
        } catch (e) {
            console.error("failed to verify third party token", e);
            return null;
        }
    }
}

