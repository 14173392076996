import {VERSION} from "../version";
import {Context, ContextKey} from "./context";

export class Logger {
    constructor(private readonly _debug: boolean) {}

    public debug(ctx: Context, message: string, data?: Record<string, unknown>): void {
        if (this._debug) {
            this._log(ctx, "debug", message, data);
        }
    }

    public info(ctx: Context, message: string, data?: Record<string, unknown>): void {
        if (this._debug) {
            this._log(ctx, "info", message, data);
        }
    }

    public warn(ctx: Context, message: string, data?: Record<string, unknown>): void {
        if (this._debug) {
            this._log(ctx, "warn", message, data);
        }
    }

    public error(ctx: Context, message: string, data?: Record<string, unknown>): void {
        this._log(ctx, "error", message, data);
    }

    private _log(ctx: Context, level: string, message: string, data?: Record<string, unknown>): void {
        const log = `${new Date().toISOString()} [${level.toUpperCase()}] ${message} ${data ? JSON.stringify(data) : ""} request=${ctx.value(ContextKey.REQUEST)} version=${VERSION}`;

        switch (level) {
        case "debug":
            console.debug(log);
            break;
        case "info":
            console.info(log);
            break;
        case "warn":
            console.warn(log);
            break;
        case "error":
            console.error(log);
            break;
        }
    }
}
