export class Context {
    private values: Map<string, any>;

    constructor() {
        this.values = new Map<string, any>();
    }

    public value<T>(key: string): T {
        return this.values.get(key);
    }

    public withValue<T>(key: string, value: T): Context {
        const newContext = new Context();
        newContext.values = new Map<string, any>(this.values);
        newContext.values.set(key, value);
        return newContext;
    }
}

export function background() {
    return new Context();
}

export enum ContextKey {
    ORIGIN = "origin",
    REQUEST = "request",
}
