import {Event} from "./events";
import {Logger} from "../lib/logger";
import {background, type Context, ContextKey} from "../lib/context";
import {uuidv7} from "../lib/uuid";

export type EventHandler = (ctx: Context, event: MessageEvent) => void;
export class EventManager {
    private handlers: Map<Event, EventHandler>;
    private logger: Logger;
    private readonly defaultOrigin: string = "embedded";

    constructor(debug: boolean) {
        this.logger = new Logger(debug);
        this.handlers = new Map<Event, EventHandler>();
    }

    on(event: Event, handler: EventHandler): void {
        this.handlers.set(event, handler);
    }

    handleEvent(event: MessageEvent): void {
        if (!event.data.action) {
            return;
        }

        let origin = "";
        try {
            origin = new URL(event.origin).host;
        } catch (e) { /* empty */ }

        if (origin === "") {
            origin = this.defaultOrigin;
        }

        let ctx = background().withValue(ContextKey.ORIGIN, origin);
        ctx = ctx.withValue(ContextKey.REQUEST, uuidv7());
        this.logger.info(ctx, "Received event", event.data);
        const handler: EventHandler | undefined = this.handlers.get(event.data.action);
        if (handler) {
            handler(ctx, event);
        }
    }

    listen(): void {
        window.addEventListener("message", this.handleEvent.bind(this));
        window.parent.postMessage({uuid: "FIRST", action: Event.LOADED}, "*");
    }

    close(): void {
        window.removeEventListener("message", this.handleEvent.bind(this));
    }


}
