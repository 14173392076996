import {Storage} from "./storage";
import {Context, ContextKey} from "../lib/context";

export class LocalStorage implements Storage {
    getItem(ctx: Context, key: string): string | null {
        const storage = localStorage.getItem(ctx.value(ContextKey.ORIGIN));
        if (!storage) {
            return null;
        }

        const data = JSON.parse(storage);
        return data[key];
    }

    setItem(ctx: Context, key: string, value: string): void {
        const storage = localStorage.getItem(ctx.value(ContextKey.ORIGIN));
        const data = storage ? JSON.parse(storage) : {};
        data[key] = value;
        localStorage.setItem(ctx.value(ContextKey.ORIGIN), JSON.stringify(data));
    }

    removeItem(ctx: Context, key: string): void {
        const storage = localStorage.getItem(ctx.value(ContextKey.ORIGIN));
        if (!storage) {
            return;
        }

        const data = JSON.parse(storage);
        delete data[key];
        localStorage.setItem(ctx.value(ContextKey.ORIGIN), JSON.stringify(data));
    }

    flush(ctx: Context): void {
        const storage = localStorage.getItem(ctx.value(ContextKey.ORIGIN));
        if (!storage) {
            return;
        }

        localStorage.setItem(ctx.value(ContextKey.ORIGIN), "{}");
    }
}

