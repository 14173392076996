import {Crypto} from "@openfort-xyz/crypto-js";
import {LocalStorage} from "../storage/local.storage";
import {
    type CustomAuthOptions, EncryptionPartMissingError,
    entropy,
    type OpenfortAuthOptions,
    OpenfortOAuthProvider,
    OpenfortOAuthTokenType,
    type Share,
    type ShieldAuthOptions,
    ShieldAuthProvider,
    ShieldSDK,
} from "@openfort/shield-js";
import {Openfort} from "../clients/openfort";
import {
    IncorrectUserEntropyError,
    MissingProjectEntropyError,
    MissingUserEntropyError,
    NotConfiguredError,
} from "./errors";
import {Logger} from "../lib/logger";
import {type Context, ContextKey} from "../lib/context";
import type {RecoveryMethod} from "../controllers/events";
import type {Storage} from "../storage/storage";

export interface ShieldAuthentication {
    auth: AuthType;
    token: string;
    authProvider?: string;
    tokenType?: string;
}

export interface AccountDevice {
    deviceID: string;
    accountType: string | null;
    chainId: number | null;
    address: string | null;
}

export enum AuthType {
    OPENFORT = "openfort",
    CUSTOM = "custom"
}

export interface JwtKey {
    kty: string;
    x: string;
    y: string;
    crv: string;
    kid: string;
    use: string;
    alg: string;
}

export interface JwtKeyResponse {
    keys: JwtKey[];
}


export interface Configuration {
    token: string;
    thirdPartyProvider?: string;
    thirdPartyTokenType?: string;
    playerID?: string;
    publishableKey: string;
    shieldAPIKey: string;
    chainId: number;
    ShieldAuthentication?: ShieldAuthentication;
    encryptionKey?: string;
    encryptionPart?: string;
    encryptionSession?: string;
    openfortURL?: string;
    shieldURL?: string;
}

export interface OpenfortConfiguration {
    token: string;
    thirdPartyProvider?: string;
    thirdPartyTokenType?: string;
    publishableKey: string;
    openfortURL?: string;
}

export class SignatureService {
    private readonly _logger: Logger;
    constructor(debugMode: boolean) {
        this._logger = new Logger(debugMode);
    }

    private readonly DEVICE_SHARE_INDEX = 0;
    private readonly AUTH_SHARE_INDEX = 1;
    private readonly RECOVERY_SHARE_INDEX = 2;
    private readonly storage: Storage = new LocalStorage();
    private _configuration: Configuration | null = null;
    private _openfortClient: Openfort | null = null;
    private _shieldSDK: ShieldSDK | null = null;

    private setConfiguration(ctx: Context, configuration: Configuration) {
        this._logger.info(ctx, "Setting configuration");
        this._openfortClient = new Openfort(configuration.publishableKey, configuration.token, configuration.thirdPartyProvider, configuration.thirdPartyTokenType, configuration.openfortURL);

        this._configuration = configuration;
        this._shieldSDK = new ShieldSDK({
            baseURL: configuration.shieldURL,
            apiKey: configuration.shieldAPIKey,
        });
    }

    async configure(ctx: Context, configuration: Configuration): Promise<AccountDevice> {
        this._logger.info(ctx, "Configuring...");
        this.setConfiguration(ctx, configuration);
        if (this._configuration === null || this._openfortClient === null || this._shieldSDK === null) {
            this._logger.error(ctx, "failed to set configuration");
            this.storage.flush(ctx);
            throw new Error("failed to set configuration");
        }

        let player = this.storage.getItem(ctx, "playerID");
        if (player && configuration.playerID && player !== configuration.playerID) {
            this._logger.warn(ctx, "Player ID mismatch, flushing stored data", {"stored": player, retrieved: configuration.playerID});
            this.storage.flush(ctx);
            player = configuration.playerID;
        }

        this._logger.info(ctx, "Checking for existing device...");
        const currentDevice = this.storage.getItem(ctx, "deviceID");
        if (currentDevice) {
            this._logger.info(ctx, "Found existing device", {currentDevice});
            return {
                deviceID: currentDevice,
                accountType: this.storage.getItem(ctx, "accountType") || null,
                chainId: this.storage.getItem(ctx, "chainId") ? Number.parseInt(this.storage.getItem(ctx, "chainId")!) : null,
                address: this.storage.getItem(ctx, "address") || null,
            };
        }

        this._logger.info(ctx, "No existing device found, creating new device...");

        if (!this._configuration.chainId) {
            this._logger.error(ctx, "no chain id");
            throw new Error("no chain id");
        }

        if (!this._configuration.ShieldAuthentication) {
            this._logger.error(ctx, "no shield authentication");
            throw new Error("no shield authentication");
        }

        const nextAction = await this._openfortClient.init(ctx, this._configuration.chainId);
        if (player && player !== nextAction.player) {
            this._logger.warn(ctx, "Player ID mismatch, flushing stored data", {"stored": player, retrieved: nextAction.player});
            this.storage.flush(ctx);
            player = nextAction.player;
        }
        if (!player) {
            player = nextAction.player;
        }

        if (nextAction.nextAction === "REGISTER") {
            this._logger.info(ctx, "No account found, registering new account...");
            try {
                return this.register(ctx, player);
            } catch (e) {
                this._logger.error(ctx, "failed to register new account");
                this.storage.flush(ctx);
                throw e;
            }
        }

        this._logger.info(ctx, "Account found, recovering account...");
        if (!nextAction.embedded) {
            this._logger.error(ctx, "failed to retrieve account information");
            throw new Error("failed to retrieve account information");
        }

        try {
            return this.recover(ctx, player, nextAction.embedded.address, nextAction.embedded.accountType, nextAction.embedded.share);
        } catch (e) {
            this._logger.error(ctx, "failed to recover account");
            this.storage.flush(ctx);
            throw e;
        }
    }

    private async register(ctx: Context, player: string): Promise<AccountDevice> {
        if (this._configuration === null || this._openfortClient === null || this._shieldSDK === null || !this._configuration.ShieldAuthentication) {
            this._logger.error(ctx, "not configured");
            throw new NotConfiguredError();
        }

        this._logger.info(ctx, "Creating new account...");
        const key = await Crypto.generate();
        if (!key.shares || key.shares.length !== 3 || !key.publicKey) {
            this._logger.error(ctx, "failed to generate key");
            throw new Error("failed to generate key");
        }

        const deviceShare = key.shares[this.DEVICE_SHARE_INDEX];
        const authShare = key.shares[this.AUTH_SHARE_INDEX];
        let recoveryShare = key.shares[this.RECOVERY_SHARE_INDEX];

        let salt = "";
        if (this._configuration.encryptionKey) {
            salt = Crypto.generateRandomSalt();
            const encryptionKey = await Crypto.deriveKey(this._configuration.encryptionKey, salt);
            recoveryShare = await Crypto.encrypt(encryptionKey, recoveryShare);
        }

        const authOptions = this.toShieldAuthOptions(this._configuration.ShieldAuthentication);
        const storeShare: Share = {
            secret: recoveryShare,
            entropy: entropy.none,
        };
        if (salt) {
            storeShare.entropy = entropy.user;
            storeShare.encryptionParameters = {
                salt: salt,
                iterations: 1000,
                length: 256,
                digest: "SHA-256",
            };
        } else if (this._configuration.encryptionPart) {
            storeShare.entropy = entropy.project;
            authOptions.encryptionPart = this._configuration.encryptionPart;
        } else if (this._configuration.encryptionSession) {
            storeShare.entropy = entropy.project;
            authOptions.encryptionSession = this._configuration.encryptionSession;
        }

        try {
            await Promise.all([
                this._shieldSDK.storeSecret(storeShare, authOptions, ctx.value(ContextKey.REQUEST)),
                this.registerDevice(ctx, player, key.publicKey, authShare, deviceShare),
            ]);
        } catch (e) {
            if (e instanceof EncryptionPartMissingError) {
                throw new MissingProjectEntropyError();
            }

            try {
                await this._shieldSDK.deleteSecret(authOptions, ctx.value(ContextKey.REQUEST));
            } catch (error) {
                this._logger.error(ctx, `failed to delete secret: ${error}`);
            }
            this._logger.error(ctx, `failed to create account or device: ${e}`);
            throw new Error(`failed to create account or device: ${e}`);
        }

        return {
            deviceID: this.storage.getItem(ctx, "deviceID")!,
            accountType: this.storage.getItem(ctx, "accountType") || null,
            chainId: this.storage.getItem(ctx, "chainId") ? parseInt(this.storage.getItem(ctx, "chainId")!) : null,
            address: this.storage.getItem(ctx, "address") || null,
        };
    }

    private async registerDevice(ctx: Context, player: string, publicKey: string, authShare: string, deviceShare: string): Promise<void> {
        const embedded = await this._openfortClient!.register(ctx, this._configuration!.chainId, publicKey, authShare);
        if (!embedded.deviceId) {
            this._logger.error(ctx, "no device ID");
            throw new Error("no device ID");
        }
        this._logger.info(ctx, "Created new account and device", {deviceID: embedded.deviceId});
        this.storage.setItem(ctx, "share", deviceShare);
        this.storage.setItem(ctx, "deviceID", embedded.deviceId);
        this.storage.setItem(ctx, "accountType", embedded.accountType);
        this.storage.setItem(ctx, "chainId", embedded.chainId.toString());
        this.storage.setItem(ctx, "address", embedded.address);
        this.storage.setItem(ctx, "playerID", player);
    }

    private async recover(ctx: Context, player: string, address: string, accountType: string, authShare: string): Promise<AccountDevice> {
        if (this._configuration === null || this._openfortClient === null || this._shieldSDK === null) {
            this._logger.error(ctx, "not configured");
            throw new NotConfiguredError();
        }

        const authOptions = this.toShieldAuthOptions(this._configuration.ShieldAuthentication!);
        if (this._configuration.encryptionPart) {
            authOptions.encryptionPart = this._configuration.encryptionPart;
        } else if (this._configuration.encryptionSession) {
            authOptions.encryptionSession = this._configuration.encryptionSession;
        }

        let recoveryShare: Share;
        try {
            recoveryShare = await this._shieldSDK.getSecret(authOptions, ctx.value(ContextKey.REQUEST));
        } catch (e) {
            if (e instanceof EncryptionPartMissingError) {
                throw new MissingProjectEntropyError();
            }

            this._logger.error(ctx, "failed to retrieve secret", {error: e});
            throw e;
        }

        if (recoveryShare.entropy === entropy.user) {
            if (!this._configuration.encryptionKey) {
                this._logger.error(ctx, "user entropy is required for this signer");
                throw new MissingUserEntropyError();
            }
            const salt = recoveryShare.encryptionParameters?.salt;
            if (!salt) {
                this._logger.error(ctx, "no salt");
                throw new Error("no salt");
            }
            const key = await Crypto.deriveKey(this._configuration.encryptionKey, salt);
            try {
                recoveryShare.secret = await Crypto.decrypt(key, recoveryShare.secret);
            } catch (error) {
                this._logger.error(ctx, "incorrect user entropy for this signer");
                throw new IncorrectUserEntropyError();
            }
        }

        const privateKey = await Crypto.combineShares([authShare, recoveryShare.secret]);
        const newShares = await Crypto.splitPrivateKey(privateKey, 3, 2);

        const newDeviceShare = newShares[this.DEVICE_SHARE_INDEX];
        const newAuthShare = newShares[this.AUTH_SHARE_INDEX];

        const embedded = await this._openfortClient.register(ctx, this._configuration.chainId, address, newAuthShare);
        if (!embedded.deviceId) {
            this._logger.error(ctx, "no device ID");
            throw new Error("no device ID");
        }

        this.storage.setItem(ctx, "share", newDeviceShare);
        this.storage.setItem(ctx, "deviceID", embedded.deviceId);
        this.storage.setItem(ctx, "accountType", accountType);
        this.storage.setItem(ctx, "chainId", this._configuration.chainId.toString());
        this.storage.setItem(ctx, "address", address);
        this.storage.setItem(ctx, "playerID", player);
        return {
            accountType: accountType,
            address: address,
            chainId: this._configuration.chainId,
            deviceID: embedded.deviceId,
        };
    }

    getCurrentDevice(ctx: Context, playerId?: string): AccountDevice | null {
        this._logger.info(ctx, "Getting current device...", {playerId});
        if (this._configuration === null || this._openfortClient === null || this._shieldSDK === null) {
            this._logger.error(ctx, "not configured");
            throw new NotConfiguredError();
        }

        if (this.storage.getItem(ctx, "playerID") && playerId && this.storage.getItem(ctx, "playerID") !== playerId) {
            this._logger.warn(ctx, "player ID mismatch");
            return null;
        }

        this._logger.info(ctx, "Configured", {player: this.storage.getItem(ctx, "playerID")});
        return {
            deviceID: this.storage.getItem(ctx, "deviceID")!,
            accountType: this.storage.getItem(ctx, "accountType") || null,
            chainId: this.storage.getItem(ctx, "chainId") ? Number.parseInt(this.storage.getItem(ctx, "chainId")!) : null,
            address: this.storage.getItem(ctx, "address") || null,
        };
    }

    async setRecoveryMethod(ctx: Context, recoveryMethod: RecoveryMethod, recoveryPassword?: string, encryptionSession?: string, configuration?: OpenfortConfiguration): Promise<void> {
        this._logger.info(ctx, "Setting recovery method...", {recoveryMethod, recoveryPassword});
        const targetEntropy = recoveryMethod === "password" ? entropy.user : entropy.project;
        if (this._configuration === null || this._shieldSDK === null || this._configuration.ShieldAuthentication === undefined) {
            this._logger.error(ctx, "not configured");
            throw new NotConfiguredError();
        }
        this._configuration.encryptionKey = recoveryPassword ?? this._configuration.encryptionKey;
        if (configuration?.token && this._configuration && this._configuration.token !== configuration.token) {
            this._logger.info(ctx, "Updating token", {token: configuration.token});
            this._configuration.token = configuration.token;
            if (this._configuration.ShieldAuthentication) {
                this._configuration.ShieldAuthentication.token = configuration.token;
            }
        }

        const authOptions = this.toShieldAuthOptions(this._configuration.ShieldAuthentication);
        if (this._configuration.encryptionPart) {
            authOptions.encryptionPart = this._configuration.encryptionPart;
        } else if (encryptionSession) {
            authOptions.encryptionSession = encryptionSession;
        }

        let recoveryShare: Share;
        try {
            recoveryShare = await this._shieldSDK.getSecret(authOptions, ctx.value(ContextKey.REQUEST));
        } catch (e) {
            if (e instanceof EncryptionPartMissingError) {
                throw new MissingProjectEntropyError();
            }

            this._logger.error(ctx, "failed to retrieve secret", {error: e});
            throw e;
        }
        // check that the current recovery share is different than the target entropy. otherwise throw an error
        if (recoveryShare.entropy === targetEntropy) {
            this._logger.error(ctx, "recovery share already has the target entropy");
            throw new Error("recovery share already has the target entropy");
        }

        if (recoveryShare.entropy === entropy.user) {
            this._logger.info(ctx, "Setting recovery method to automatic...");
            if (!this._configuration.encryptionKey) {
                this._logger.error(ctx, "user entropy is required for this signer");
                throw new MissingUserEntropyError();
            }
            const salt = recoveryShare.encryptionParameters?.salt;
            if (!salt) {
                this._logger.error(ctx, "no salt");
                throw new Error("no salt");
            }
            const key = await Crypto.deriveKey(this._configuration.encryptionKey, salt);
            try {
                recoveryShare.secret = await Crypto.decrypt(key, recoveryShare.secret);
                recoveryShare.encryptionParameters = undefined;
            } catch (error) {
                this._logger.error(ctx, "incorrect user entropy for this signer");
                throw new IncorrectUserEntropyError();
            }
        }
        if(recoveryShare.entropy === entropy.project) {
            this._logger.info(ctx, "Setting recovery method to user entropy...");
            if(!recoveryPassword) {
                this._logger.error(ctx, "recovery password is required for this signer");
                throw new MissingUserEntropyError();
            }
            const salt = Crypto.generateRandomSalt();
            recoveryShare.encryptionParameters = {
                salt: salt,
                iterations: 1000,
                length: 256,
                digest: "SHA-256",
            };
            const encryptionKey = await Crypto.deriveKey(recoveryPassword, salt);
            recoveryShare.secret = await Crypto.encrypt(encryptionKey, recoveryShare.secret);
        }
        const share: Share = {
            entropy: targetEntropy,
            secret: recoveryShare.secret,
            encryptionParameters: recoveryShare.encryptionParameters,
        };
        await this._shieldSDK.updateSecret(authOptions, share);
    }

    async sign(ctx: Context, message:  string | Uint8Array, requireArrayify=true, requireHash=true, configuration?: OpenfortConfiguration): Promise<string> {
        this._logger.info(ctx, "Signing...", {message, requireArrayify, requireHash});
        if (this._openfortClient === null) {
            if (!configuration) {
                this._logger.error(ctx, "no configuration");
                throw new NotConfiguredError();
            }
            this._openfortClient = new Openfort(configuration.publishableKey, configuration.token, configuration.thirdPartyProvider, configuration.thirdPartyTokenType, configuration.openfortURL);
        }

        if (configuration?.token && this._configuration && this._configuration.token !== configuration.token) {
            this._logger.info(ctx, "Updating token", {token: configuration.token});
            this._configuration.token = configuration.token;
            this._openfortClient.setAccessToken(configuration.token);
            if (this._configuration.ShieldAuthentication) {
                this._configuration.ShieldAuthentication.token = configuration.token;
            }
        }

        this._logger.info(ctx, "Reconstructing signing key...");
        const deviceShare = this.storage.getItem(ctx, "share");
        if (!deviceShare) {
            this._logger.error(ctx, "no share found");
            throw new NotConfiguredError();
        }

        const deviceID = this.storage.getItem(ctx, "deviceID");
        if (!deviceID) {
            this._logger.error(ctx, "no deviceID found");
            throw new NotConfiguredError();
        }

        const device = await this._openfortClient.getDevice(ctx, deviceID);
        const combined = await Crypto.combineShares([device.share, deviceShare]);

        this._logger.info(ctx, "Signing message...", {message});
        try {
            return Crypto.signMessage(message, combined, requireArrayify, requireHash);
        } catch (e) {
            this._logger.error(ctx, "failed to sign message", {error: e});
            throw new Error(`failed to sign message: ${e}`);
        }
    }

    async export(ctx: Context, configuration?: OpenfortConfiguration): Promise<string> {
        if (this._openfortClient === null) {
            if (!configuration) {
                this._logger.error(ctx, "no configuration");
                throw new NotConfiguredError();
            }
            this._openfortClient = new Openfort(configuration.publishableKey, configuration.token, configuration.thirdPartyProvider, configuration.thirdPartyTokenType, configuration.openfortURL);
        }

        if (configuration?.token && this._configuration && this._configuration.token !== configuration.token) {
            this._logger.info(ctx, "Updating token", {token: configuration.token});
            this._configuration.token = configuration.token;
            this._openfortClient.setAccessToken(configuration.token);
            if (this._configuration.ShieldAuthentication) {
                this._configuration.ShieldAuthentication.token = configuration.token;
            }
        }

        const address = this.storage.getItem(ctx, "address");
        if (!address) {
            this._logger.error(ctx, "no address found");
            throw new NotConfiguredError();
        }

        this._logger.info(ctx, "Reconstructing signing key...");
        const deviceShare = this.storage.getItem(ctx, "share");
        if (!deviceShare) {
            this._logger.error(ctx, "no share found");
            throw new NotConfiguredError();
        }

        const deviceID = this.storage.getItem(ctx, "deviceID");
        if (!deviceID) {
            this._logger.error(ctx, "no deviceID found");
            throw new NotConfiguredError();
        }

        const device = await this._openfortClient.getDevice(ctx, deviceID);


        const key = await Crypto.combineShares([device.share, deviceShare]);
        await this._openfortClient.exported(ctx, address);
        return key;
    }

    logout(ctx: Context) {
        this._logger.info(ctx, "Logging out...");
        this.storage.flush(ctx);
    }

    updateAuthentication(ctx: Context, accessToken: string) {
        this._logger.info(ctx, "Updating authentication...");
        if (this._configuration === null || this._openfortClient === null) {
            this._logger.error(ctx, "not configured");
            throw new NotConfiguredError();
        }

        this._configuration.token = accessToken;
        this._openfortClient.setAccessToken(accessToken);
        if (this._configuration.ShieldAuthentication) {
            this._configuration.ShieldAuthentication.token = accessToken;
        }
    }

    private toShieldAuthOptions(auth: ShieldAuthentication): ShieldAuthOptions {
        let opts: ShieldAuthOptions;
        if (auth.auth === AuthType.OPENFORT) {
            const ofOpts: OpenfortAuthOptions = {
                authProvider: ShieldAuthProvider.OPENFORT,
                openfortOAuthToken: auth.token,
                openfortOAuthProvider: this.toOpenfortOAuthProvider(auth.authProvider),
                openfortOAuthTokenType: this.toOpenfortTokenType(auth.tokenType),
            };
            opts = ofOpts;
        } else if (auth.auth === AuthType.CUSTOM) {
            const cOpts: CustomAuthOptions = {
                authProvider: ShieldAuthProvider.CUSTOM,
                customToken: auth.token,
            };
            opts = cOpts;
        } else {
            throw new Error("unknown auth type");
        }

        return opts;
    }

    private toOpenfortOAuthProvider(provider: string | undefined): OpenfortOAuthProvider | undefined {
        switch (provider) {
        case "accelbyte":
            return OpenfortOAuthProvider.ACCELBYTE;
        case "firebase":
            return OpenfortOAuthProvider.FIREBASE;
        case "supabase":
            return OpenfortOAuthProvider.SUPABASE;
        case "lootlocker":
            return OpenfortOAuthProvider.LOOTLOCKER;
        case "playfab":
            return OpenfortOAuthProvider.PLAYFAB;
        case "custom":
            return OpenfortOAuthProvider.CUSTOM;
        case "oidc":
            return OpenfortOAuthProvider.OIDC;
        default:
        }

        return undefined;
    }

    private toOpenfortTokenType(tokenType: string | undefined): OpenfortOAuthTokenType | undefined {
        switch (tokenType) {
        case "customToken":
            return OpenfortOAuthTokenType.CUSTOM_TOKEN;
        case "idToken":
            return OpenfortOAuthTokenType.ID_TOKEN;
        default:
        }

        return undefined;
    }
}

